import { Col, Container, Row } from "react-bootstrap";
import "./Innersection.scss";
import HomeinnerSlider from "../Slider/HomeinnerSlider";
const HomeInnersection = () => {
  return (
    <>
      <Container className="Innersection">
        <Row>
          <Col md={8} xs={12}>
            <h1 class="display-5 InnersectionHead">
              Willkommen bei Wärmepumpen & Klimatechnik Bilke{" "}
            </h1>
            <blockquote class="blockquote">
              <p class="mb-0">
                Ihren vertrauenswürdigen Experten für Klimaanlagen
              </p>
            </blockquote>
            <p>
              Wir sind auf die Bereitstellung hochwertiger
              Klimatisierungslösungen für Wohn- und Gewerberäume spezialisiert.
              Mit über Jahren Erfahrung ist unser Team bestrebt, diese
              Herausforderungen anzugehen.
            </p>
            <blockquote class="blockquote">Warum uns wählen?</blockquote>
            <ul>
              <li>
                <b>Zertifizierte HVAC-Experten:</b> Unsere Techniker sind
                vollständig zertifiziert und bringen jahrelange Erfahrung mit.
              </li>
              <li>
                <b>Notdienst rund um die Uhr:</b>Wir sind rund um die Uhr für
                Sie da, damit Sie kühl und komfortabel bleiben
              </li>
              <li>
                <b>Energieeffiziente Systeme:</b>Senken Sie Ihre Energiekosten
                mit unseren umweltfreundlichen AC-Geräten.
              </li>
              <li>
                <b>Zufriedenheitsgarantie:</b>Ihr Komfort steht bei uns an
                erster Stelle und wir möchten Ihre Erwartungen übertreffen.
              </li>
            </ul>
          </Col>
          <Col md={4} xs={12}>
            <HomeinnerSlider></HomeinnerSlider>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default HomeInnersection;
