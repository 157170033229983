import { Carousel } from "react-bootstrap";
import "./HomeinnerSlider.scss";
import InnersectionImage1 from "../../Assect/Image/InnersectionImg1.jpeg";
import InnersectionImage2 from "../../Assect/Image/InnersectionImg2.jpeg";
const HomeinnerSlider = () => {
  return (
    <>
      <Carousel interval={3000} fade>
        <Carousel.Item>
          <img
            className="d-block w-200"
            src={InnersectionImage1}
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-200"
            src={InnersectionImage2}
            alt="Second slide"
          />
        </Carousel.Item>
      </Carousel>
    </>
  );
};
export default HomeinnerSlider;
