import "./CompanyPageHead.scss";
const ImpressumHead = () => {
  return (
    <>
      <div className="PageHeadText">
        <h2>Impressum</h2>
      </div>
    </>
  );
};
export default ImpressumHead;
