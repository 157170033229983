import React, { useEffect } from "react";
import "./Banner.scss";
import bannerImg from "../../Assect/Image/comeercial-banner.jpeg";
const Banner = () => {
  return (
    <div className="banner">
      <div className="image-container">
        <img src={bannerImg} alt="Banner" />
      </div>
      <div className="banner-content">
        <h1 className="h1Banner">Wärmepumpen & Klimatechnik Bilke</h1>
        <p className="PBanner">Wir beraten Sie gern</p>
      </div>
    </div>
  );
};

export default Banner;
