import CompanyCardsection from "./Card/CompanyCardsection";
import CompanyInnerSection from "./Innersections/CompanyInnerSection";
import CompanyPageHead from "./PagesNameHead/CompanyPageHead";
import Snowfall from "./SnowFall/SnowFall";

const Company = () => {
  return (
    <>
      <CompanyPageHead />
      <CompanyInnerSection />
      <CompanyCardsection />
      <Snowfall />
    </>
  );
};
export default Company;
