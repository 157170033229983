import ReferencesCardSection from "./Card/ReferencesCardSection";
import ReferencesInnerSection from "./Innersections/ReferencesInnerSection";
import ReferencesHead from "./PagesNameHead/ReferencesHead";

const References = () => {
  return (
    <>
      <ReferencesHead />
      <ReferencesInnerSection />
      <ReferencesCardSection />
    </>
  );
};
export default References;
