import "./Innersection.scss";
import { Col, Container, Row } from "react-bootstrap";
import ImpressumImg from "../../Assect/Image/Impressum-Img.jpg";
const ImpressumInnerSection = () => {
  return (
    <>
      <Container className="Innersection">
        <p className="display-6 InnersectionHead">Haftungsausschluss:</p>

        <h5>Haftung für Inhalte</h5>
        <p>
          Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für
          die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir
          jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7
          Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen
          Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
          Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
          gespeicherte fremde Informationen zu überwachen oder nach Umständen zu
          forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
        </p>

        <h5>Haftung für Links</h5>
        <p>
          Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren
          Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
          fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
          verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der
          Seiten verantwortlich.
        </p>

        <h5>Urheberrecht</h5>
        <p>
          Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
          Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
          Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
          Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
          jeweiligen Autors bzw. Erstellers.
        </p>

        <Row>
          <Col md={6}>
            <h5>Rechtlicher Hinweis</h5>
            <p className="lead">Wärmepumpen & Klimatechnik Bilke</p>

            <p>Inhaber: [Your Full Name]</p>
            <p>Adresse: [Street Name, Postal Code, City, Country]</p>
            <p>Telefon: [Phone Number]</p>
            <p>E-Mail: [Email Address]</p>
            <p>Webseite: [Website URL]</p>

            <p className="lead">USt-IdNr. (VAT Identification Number):</p>
            <p>[VAT Number]</p>

            <p className="lead">Gewerbeanmeldung:</p>
            <p>Angemeldet beim Gewerbeamt [Your City]</p>
            <p>Gewerbenummer: [Your Business Number]</p>
          </Col>
          <Col md={6}>
            <img src={ImpressumImg} className="img-fluid" />
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default ImpressumInnerSection;
