import { Container } from "react-bootstrap";
import "./Card.scss";
import Img1 from "../../Assect/Image/CompanyImg.jpg";
const CompanyCardsection = () => {
  return (
    <>
      <Container>
        <div className="CompanyCardImage">
          <img src={Img1} className="img-fluid "></img>
        </div>
      </Container>
    </>
  );
};
export default CompanyCardsection;
