import "./Innersection.scss";
import { Container } from "react-bootstrap";

const ReferencesInnerSection = () => {
  return (
    <>
      <Container className="Innersection">
        <p className="display-6 InnersectionHead">
          Willkommen bei Wärmepumpen & Klimatechnik Bilke
        </p>
        <p>
          Bei Wärmepumpen & Klimatechnik Bilke sind wir stolz darauf,
          hochwertige Klimatisierungslösungen für Wohn-, Gewerbe- und
          Industrieprojekte anzubieten. Nachfolgend finden Sie einige
          bemerkenswerte Projekte, die wir für unsere zufriedenen Kunden
          abgeschlossen haben.
        </p>
      </Container>
    </>
  );
};
export default ReferencesInnerSection;
