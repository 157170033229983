import ContactInnerSection from "./Innersections/ContactInnerSection";
import ContactHead from "./PagesNameHead/ContactHead";

const Contactpage = () => {
  return (
    <>
      <ContactHead />
      <ContactInnerSection />
    </>
  );
};
export default Contactpage;
