import { Container } from "react-bootstrap";
import "./Innersection.scss";

const CompanyInnerSection = () => {
  return (
    <>
      <Container className="Innersection">
        <p className="display-6 InnersectionHead">
          Willkommen bei Wärmepumpen & Klimatechnik Bilke
        </p>
        <p className="lead">
          Seit über Jahren bieten wir erstklassige
          Klimatisierungsdienstleistungen an. Als lokales Unternehmen wissen
          wir, wie wichtig zuverlässige Kühlsysteme sind, um Ihr Zuhause oder
          Ihr Unternehmen das ganze Jahr über komfortabel zu halten.
        </p>
        <p className="lead">
          Unsere zertifizierten HVAC-Techniker sind in den neuesten Technologien
          geschult und bestrebt, schnellen, professionellen Service mit einem
          Lächeln zu liefern. Von kleinen Reparaturen in Privathaushalten bis
          hin zu großen gewerblichen Installationen erledigen wir jeden Auftrag
          mit Sorgfalt und Präzision.
        </p>
        <h5>Unsere Mission</h5>
        <p>
          Unsere Mission ist es, qualitativ hochwertige
          Klimatisierungsdienstleistungen anzubieten und dabei die
          Kundenzufriedenheit und Umweltverantwortung im Fokus zu haben. Wir
          sind bestrebt, energieeffiziente Lösungen anzubieten, die nicht nur
          für Ihren Komfort sorgen, sondern auch zu einer umweltfreundlicheren
          Zukunft beitragen.
        </p>
        <h5>Unsere Werte</h5>
        <p>
          <strong>Integrität :</strong> Wir glauben an transparente Preise,
          ehrliche Beratung und die ständige Einhaltung unserer Versprechen.
        </p>
        <p>
          <strong>Innovation :</strong> Durch den Einsatz der neuesten
          HVAC-Technologien stellen wir sicher, dass unsere Kunden von
          modernsten, energiesparenden Lösungen profitieren.
        </p>
        <p>
          <strong>Qualität :</strong> Jedes Projekt, egal wie groß oder klein,
          wird mit den höchsten Standards an Handwerkskunst und Sorgfalt
          abgeschlossen.
        </p>
        <p>
          <strong>Nachhaltigkeit :</strong> Wir fördern den Einsatz
          umweltfreundlicher Systeme, um den Energieverbrauch und die
          Umweltbelastung zu reduzieren.
        </p>
        <h5>Unser Team</h5>
        <p>
          Hinter unserem Erfolg steht ein Team engagierter Fachleute, die mit
          Leidenschaft bei der Sache sind. Unsere Techniker sind voll
          lizenziert, versichert und verpflichten sich, außergewöhnlichen
          Service zu bieten. Vom Moment Ihrer Kontaktaufnahme bis zum Abschluss
          Ihres Projekts erhalten Sie einen freundlichen, professionellen und
          effizienten Service.
        </p>
      </Container>
    </>
  );
};
export default CompanyInnerSection;
