import ImpressumInnerSection from "./Innersections/ImpressumInnerSection";
import ImpressumHead from "./PagesNameHead/ImpressumHead";

const Impresspage = () => {
  return (
    <>
      <ImpressumHead />
      <ImpressumInnerSection />
    </>
  );
};
export default Impresspage;
