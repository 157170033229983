import "./CompanyPageHead.scss";
const ReferencesHead = () => {
  return (
    <>
      <div className="PageHeadText">
        <h2>References</h2>
      </div>
    </>
  );
};
export default ReferencesHead;
