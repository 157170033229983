import { Col, Container, Row } from "react-bootstrap";
import BannerComponet from "./Banner/Banner";
import HomeInnersection from "./Innersections/HomeInnersection";

const Hometpage = () => {
  return (
    <>
      <BannerComponet></BannerComponet>
      <Container>
        <Row>
          <Col mt={10}>
            <HomeInnersection />
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default Hometpage;
