import { Nav } from "react-bootstrap";
import "./Footer.scss";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer class="bg-color text-white pt-4">
        <div class="container">
          <div class="row">
            <div class="col-md-4">
              <h5>Über uns</h5>
              <p>
                Bei Wärmepumpen & Klimatechnik Bilke sind wir auf die
                Bereitstellung energieeffizienter Heiz- und Kühllösungen
                spezialisiert, die auf Ihre Komfortbedürfnisse zugeschnitten
                sind.
              </p>
            </div>

            <div class="col-md-4">
              <h5>Quick Links</h5>
              <Nav className="flex-column">
                <Nav.Link as={Link} to="/" className="Footer-link">
                  Home
                </Nav.Link>
                <Nav.Link as={Link} to="/Company" className="Footer-link">
                  Company
                </Nav.Link>
                <Nav.Link as={Link} to="/Impresspage" className="Footer-link">
                  Impressum
                </Nav.Link>
                <Nav.Link as={Link} to="/References" className="Footer-link">
                  References
                </Nav.Link>
                <Nav.Link as={Link} to="/Contact" className="Footer-link">
                  Kontakt
                </Nav.Link>
              </Nav>
            </div>

            <div class="col-md-4">
              <h5>Kontaktieren Sie uns</h5>
              <p>Email: info@example.com</p>
              <p>Phone: +123 456 7890</p>
              <p>Location: 123 Street, City, Country</p>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col text-center">
              <a href="#" class="text-white mx-2">
                <i class="fab fa-facebook"></i>
              </a>
              <a href="#" class="text-white mx-2">
                <i class="fab fa-twitter"></i>
              </a>
              <a href="#" class="text-white mx-2">
                <i class="fab fa-instagram"></i>
              </a>
              <a href="#" class="text-white mx-2">
                <i class="fab fa-linkedin"></i>
              </a>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col text-center">
              <p class="mb-0">
                &copy; 2024 Bei Wärmepumpen & Klimatechnik Bilke. Alle Rechte
                vorbehalten.
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
