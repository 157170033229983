import "./CompanyPageHead.scss";
const CompanyPageHead = () => {
  return (
    <>
      <div className="PageHeadText">
        <h2>Über uns</h2>
      </div>
    </>
  );
};
export default CompanyPageHead;
