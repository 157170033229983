import React, { useState } from "react";
import { Container, Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import Masonry from "react-masonry-css";
import "./Card.scss";

// Importing images
import REfImg1 from "../../Assect/Image/RefImg/RefImg1.jpg";
import REfImg2 from "../../Assect/Image/RefImg/RefImg2.jpg";
import REfImg3 from "../../Assect/Image/RefImg/RefImg3.jpg";
import REfImg4 from "../../Assect/Image/RefImg/RefImg4.jpg";
import REfImg5 from "../../Assect/Image/RefImg/RefImg5.jpg";
import REfImg6 from "../../Assect/Image/RefImg/RefImg6.jpg";
import REfImg7 from "../../Assect/Image/RefImg/RefImg7.jpg";
import REfImg8 from "../../Assect/Image/RefImg/RefImg8.jpg";
import REfImg9 from "../../Assect/Image/RefImg/RefImg9.jpg";
import REfImg10 from "../../Assect/Image/RefImg/RefImg10.jpg";
import REfImg11 from "../../Assect/Image/RefImg/RefImg11.jpg";
import REfImg12 from "../../Assect/Image/RefImg/RefImg12.jpg";
import REfImg13 from "../../Assect/Image/RefImg/RefImg13.jpg";
import REfImg14 from "../../Assect/Image/RefImg/RefImg14.jpg";
import REfImg15 from "../../Assect/Image/RefImg/RefImg15.jpg";
import REfImg16 from "../../Assect/Image/RefImg/RefImg16.jpg";
import REfImg17 from "../../Assect/Image/RefImg/RefImg17.jpg";
import REfImg18 from "../../Assect/Image/RefImg/RefImg18.jpg";
import REfImg19 from "../../Assect/Image/RefImg/RefImg19.jpg";
import REfImg20 from "../../Assect/Image/RefImg/RefImg20.jpg";
import REfImg21 from "../../Assect/Image/RefImg/RefImg21.jpg";
import REfImg22 from "../../Assect/Image/RefImg/RefImg22.jpg";
import REfImg23 from "../../Assect/Image/RefImg/RefImg23.jpg";
import REfImg24 from "../../Assect/Image/RefImg/RefImg24.jpg";
import REfImg25 from "../../Assect/Image/RefImg/RefImg25.jpg";
import REfImg26 from "../../Assect/Image/RefImg/RefImg26.jpg";
import REfImg27 from "../../Assect/Image/RefImg/RefImg27.jpg";
const images = [
  { id: 1, src: REfImg1, alt: "Image 1" },
  { id: 2, src: REfImg2, alt: "Image 2" },
  { id: 3, src: REfImg3, alt: "Image 3" },
  { id: 4, src: REfImg4, alt: "Image 4" },
  { id: 5, src: REfImg5, alt: "Image 5" },
  { id: 6, src: REfImg6, alt: "Image 6" },
  { id: 7, src: REfImg7, alt: "Image 7" },
  { id: 8, src: REfImg8, alt: "Image 8" },
  { id: 9, src: REfImg9, alt: "Image 9" },
  { id: 10, src: REfImg10, alt: "Image 10" },
  { id: 11, src: REfImg11, alt: "Image 11" },
  { id: 12, src: REfImg12, alt: "Image 12" },
  { id: 13, src: REfImg13, alt: "Image 13" },
  { id: 14, src: REfImg14, alt: "Image 14" },
  { id: 15, src: REfImg15, alt: "Image 15" },
  { id: 16, src: REfImg16, alt: "Image 16" },
  { id: 17, src: REfImg17, alt: "Image 17" },
  { id: 18, src: REfImg18, alt: "Image 18" },
  { id: 19, src: REfImg19, alt: "Image 19" },
  { id: 20, src: REfImg20, alt: "Image 20" },
  { id: 21, src: REfImg21, alt: "Image 21" },
  { id: 22, src: REfImg22, alt: "Image 22" },
  { id: 23, src: REfImg23, alt: "Image 23" },
  { id: 24, src: REfImg24, alt: "Image 24" },
  { id: 25, src: REfImg25, alt: "Image 25" },
  { id: 26, src: REfImg26, alt: "Image 26" },
  { id: 27, src: REfImg27, alt: "Image 27" },
];

const ReferencesCardSection = () => {
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const imagesPerPage = 12;

  // Lightbox state
  const [isOpen, setIsOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(null);

  // Breakpoints for the masonry layout
  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1,
  };

  // Function to open the lightbox
  const openLightbox = (index) => {
    setCurrentImageIndex(index);
    setIsOpen(true);
  };

  // Function to close the lightbox
  const closeLightbox = () => {
    setIsOpen(false);
  };

  // Pagination: Get the images for the current page
  const indexOfLastImage = currentPage * imagesPerPage;
  const indexOfFirstImage = indexOfLastImage - imagesPerPage;
  const currentImages = images.slice(indexOfFirstImage, indexOfLastImage);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <Container>
      <div className="masonry-gallery">
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {currentImages.map((image, index) => (
            <div key={image.id} className="masonry-item">
              <img
                src={image.src}
                alt={image.alt}
                onClick={() =>
                  openLightbox(index + (currentPage - 1) * imagesPerPage)
                } // Open lightbox with correct index
                className="gallery-image"
              />
            </div>
          ))}
        </Masonry>
      </div>

      {/* Lightbox Modal */}
      {isOpen && (
        <Modal show={isOpen} onHide={closeLightbox} size="lg" centered>
          <Modal.Body>
            <div className="lightbox-content">
              <FontAwesomeIcon
                icon={faChevronLeft}
                className="lightbox-icon"
                onClick={() =>
                  setCurrentImageIndex(
                    (currentImageIndex + images.length - 1) % images.length
                  )
                }
              />
              <img
                src={images[currentImageIndex].src}
                alt={images[currentImageIndex].alt}
                className="lightbox-image"
              />
              <FontAwesomeIcon
                icon={faChevronRight}
                className="lightbox-icon"
                onClick={() =>
                  setCurrentImageIndex((currentImageIndex + 1) % images.length)
                }
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeLightbox}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {/* Pagination */}
      <div className="pagination ">
        {Array.from(
          { length: Math.ceil(images.length / imagesPerPage) },
          (_, i) => (
            <button
              key={i}
              className={`pagination-button ${
                i + 1 === currentPage ? "active" : ""
              }`}
              onClick={() => paginate(i + 1)}
            >
              {i + 1}
            </button>
          )
        )}
      </div>
    </Container>
  );
};

export default ReferencesCardSection;
