import "./SnowFall.scss";
import React, { useEffect } from "react";

const Snowfall = () => {
  useEffect(() => {
    createSnowflakes();
  }, []);

  const createSnowflakes = () => {
    const snowContainer = document.querySelector(".snow-container");
    for (let i = 0; i < 300; i++) {
      const snowflake = document.createElement("div");
      snowflake.classList.add("snowflake");
      snowflake.innerHTML = "❆";
      snowflake.style.left = `${Math.random() * 1000}vw`; // Randomize horizontal position
      snowflake.style.animationDuration = `${Math.random() * 10 + 2}s`; // Randomize speed
      snowflake.style.opacity = `${Math.random()}`; // Randomize opacity
      snowContainer.appendChild(snowflake);
    }
  };

  return <div className="snow-container"></div>;
};

export default Snowfall;
