import "./App.css";

import { BrowserRouter, Route, Router, Routes } from "react-router-dom";
import Hometpage from "./Component/Home";
import Company from "./Component/Company";
import Impresspage from "./Component/Impresspage";
import References from "./Component/References";
import Contactpage from "./Component/Contact";
import MenuHead from "./Component/Header/Header";
import Footer from "./Component/Footer/Footer";

function App() {
  return (
    <>
      <BrowserRouter>
        <MenuHead />
        <Routes>
          <Route path="/" element={<Hometpage />}></Route>
          <Route path="/Company" element={<Company />}></Route>
          <Route path="/Impresspage" element={<Impresspage />}></Route>
          <Route path="/References" element={<References />}></Route>
          <Route path="/Contact" element={<Contactpage />}></Route>
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
