import "./CompanyPageHead.scss";
const ContactHead = () => {
  return (
    <>
      <div className="PageHeadText">
        <h2>Kontakt</h2>
      </div>
    </>
  );
};
export default ContactHead;
