import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaEnvelope,
  FaGlobe,
} from "react-icons/fa";
import "./Innersection.scss"; // Optional for custom styling

const ContactInnerSection = () => {
  return (
    <>
      <Container className="Innersection ">
        <p className="display-6 InnersectionHead">
          Sind Sie bereit, erstklassigen Komfort zu erleben?
        </p>
        <p>
          Kontaktieren Sie noch heute Willkommen bei Wärmepumpen & Klimatechnik
          Bilke und erfahren Sie, warum uns so viele Kunden bei allen Fragen zur
          Klimatisierung vertrauen. Wir helfen Ihnen dabei, ein komfortables,
          energieeffizientes Raumklima zu schaffen.
        </p>
        <p>
          Rufen Sie uns unter <b>+49 172 3762581</b> an oder fordern Sie online
          ein Angebot an.
        </p>

        <Row>
          <Col md={6} lg={3} className="mb-4">
            <div className="contact-item text-center">
              <FaMapMarkerAlt className="contact-icon" />
              <h5>Adresse</h5>
              <p>Saubach, Germany, 06647</p>
            </div>
          </Col>

          <Col md={6} lg={3} className="mb-4">
            <div className="contact-item text-center">
              <FaPhoneAlt className="contact-icon" />
              <h5>Mobil</h5>
              <p>
                <a href="tel:+491723762581">+49 172 3762581</a>
              </p>
            </div>
          </Col>

          <Col md={6} lg={3} className="mb-4">
            <div className="contact-item text-center">
              <FaEnvelope className="contact-icon" />
              <h5>Email</h5>
              <p>
                <a href="mailto:info@wk-bilke.de">info@wk-bilke.de</a>
              </p>
            </div>
          </Col>

          <Col md={6} lg={3} className="mb-4">
            <div className="contact-item text-center">
              <FaGlobe className="contact-icon" />
              <h5>Website</h5>
              <p>
                <a
                  href="http://www.wk-bilke.de/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.wk-bilke.de
                </a>
              </p>
            </div>
          </Col>
        </Row>

        <div className="map-section mt-4">
          <iframe
            title="Google Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2532.9982964511144!2d11.516196124801192!3d51.212437771645235!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a426b8fe7611db%3A0xd47e468715e645e2!2sW%C3%A4rmepumpen%20%26%20Klimatechnik%20Bilke!5e0!3m2!1sen!2sin!4v1694786604654!5m2!1sen!2sin"
            style={{
              border: "0",
              width: "100%",
              height: "400px",
              borderRadius: "10px",
            }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      </Container>
    </>
  );
};

export default ContactInnerSection;
