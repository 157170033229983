import { Container, Nav, Navbar } from "react-bootstrap";
import logo from "../../Assect/Image/Logo.jpeg";
import "./Header.scss";
import { Link } from "react-router-dom";

const MenuHead = () => {
  return (
    <>
      <Navbar bg="white" expand="lg" data-bs-theme="light">
        <Container>
          <Navbar.Brand as={Link} to="/">
            <img src={logo} alt="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            {/* Change "ml-auto" to "ms-auto" for right alignment */}
            <Nav className="ms-auto">
              <Nav.Link as={Link} to="/" className="customNav">
                Home
              </Nav.Link>
              <Nav.Link as={Link} to="/Company" className="customNav">
                Company
              </Nav.Link>
              <Nav.Link as={Link} to="/Impresspage" className="customNav">
                Impressum
              </Nav.Link>
              <Nav.Link as={Link} to="/References" className="customNav">
                References
              </Nav.Link>
              <Nav.Link as={Link} to="/Contact" className="customNav">
                Kontakt
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};
export default MenuHead;
